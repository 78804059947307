import { Module } from "vuex";
import { getWxConfig } from "@/api/opened";

export declare interface IAppData {
  wxConfig: { appId: string, appSecret: string } | null;
  wxData: unknown | null;
}

const module: Module<IAppData, any> = {
  namespaced: true,
  state: () => ({ wxConfig: null, wxData: null }),
  mutations: {
    setWxConfig(state, config) {
      state.wxConfig = config;
    },
    setWxData(state, data) {
      state.wxData = data;
    }
  },
  getters: {
    requiredData(state) {
      return state.wxConfig;
    }
  },
  actions: {
    async createRequiredData({ commit }) {
      const config = await getWxConfig();
      if (config) {
        commit("setWxConfig", config);
      }
    }
  }
};

export default module;
