import { InjectionKey } from "vue";
import { createStore, useStore as baseUse } from "vuex";

import { module as user } from "./module/user";
import { default as app } from "./module/app";

const store = createStore({
  mutations: {},
  actions: {},
  modules: { user, app }
});

export default store;

export const storeKey: InjectionKey<typeof store> = Symbol();

export function useStore() {
  return baseUse(storeKey);
}
