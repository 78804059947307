import service from "@/utils/request";

const api = {
  me: "/users/me",
  pushRule: "/users/push-rule",
  weixinBinding: "/users/weixin-binding"
};

export function getUserInfo() {
  return service.get(api.me);
}

export function getPushRule(): Promise<any> {
  return service.get(api.pushRule);
}

export function savePushRule(rulesConfig: any): Promise<any> {
  return service.put(api.pushRule, rulesConfig);
}

export function unBindWx() {
  return service.delete(api.weixinBinding);
}
