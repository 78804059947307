import router from "@/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import { RouteLocation } from "vue-router";
import { isEmpty } from "@/utils/helper";
import { Dialog, Notify } from "vant";

NProgress.configure({ showSpinner: false });

const isFirstLoad = true;

/**
 * 是否需要验证权限
 * @param route {RouteLocation}
 */
function isAuth(route: RouteLocation): boolean {
  return route.matched.length === 0 || route.meta.auth === true || route.matched.some(r => r.meta.auth === true);
}

router.beforeEach((to, from, next) => {
  // Start progress bar
  NProgress.start();
  // Determine whether the user has logged in
  let p = Promise.resolve();
  if (isEmpty(store.getters["app/requiredData"])) {
    p = store.dispatch("app/createRequiredData");
  }
  p.then(doNext);

  function doNext() {
    if (store.getters["user/userInfo"]) {
      if (to.path.indexOf("/login") > -1) {
        // If is logged in, redirect to the home page
        next({ path: "/" });
        NProgress.done();
      } else {
        next();
      }
    } else {
      // Has no token
      if (!isAuth(to) && to.name !== "not-found") {
        // In the free login whitelist, go directly
        next();
      } else {
        if (to.name === "not-found" && !isFirstLoad) {
          next({ path: "/404" });
        } else {
          store.dispatch("user/getUserInfo").then(() => {
            let pending = Promise.resolve<any>("");
            const userInfo = store.getters["user/userInfo"];
            if (userInfo) {
              if (userInfo.disabled === true) {
                pending = Dialog({ message: "您已被管理员禁止登录" });
                pending = pending.then(() => store.commit("user/setUserInfo", null));
                pending = pending.then(() => next({ name: "no-perm", replace: true }));
                return pending;
              }
              // 请求系统必须数据
              pending = Promise.resolve();
              // pending = store.dispatch("app/createAppData");

              // 加载完成后进入页面
              pending = pending.then(() => {
                next({ path: to.path, query: to.query, replace: true });
              }).catch((e) => {
                // 忽略错误
                console.error(e);
              });
            } else {
              if (from.name !== "login") {
                next({ name: "login", query: { redirect: to.path } });
              }
            }
            return pending;
          }).catch((e) => {
            // if (e.isAxiosError && e.response && e.response.status === 401) {
            //   Notify({ type: "danger", message: "您的会话已经过期或没有权限访问该数据，请重新登录" });
            // } else {
            //   debugger;
            //   // Notify({ type: "danger", message: e.message });
            // }
            if (from.name !== "login") {
              // Other pages that do not have permission to access are redirected to the login page.
              next({ name: "login", query: { redirect: to.path } });
            }
            if (process.env.NODE_ENV !== "production") {
              console.error(e);
            }
          }).finally(() => {
            NProgress.done();
          });
        }
      }
    }
  }
});

router.afterEach(() => {
  // Finish progress bar
  NProgress.done();
});
