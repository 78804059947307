import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { SERVER_NO_ERROR, SESSION_EXPIRED } from "@/constants/message";
import NProgress from "nprogress";

import store from "@/store";
import router from "@/router";

import { Notify } from "vant";
import { isEmpty } from "@/utils/helper";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 0
});

// Request interceptors
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    NProgress.start();
    // Add X-Access-Token header to every request, you can add other custom headers here
    // const token = store2.get(tokenKey);
    // if (config.headers) {
    //   config.headers["x-okapi-tenant"] = process.env.VUE_APP_TENANT;
    //   if (token) {
    //     config.headers["x-okapi-token"] = token;
    //   }
    // }
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

const skipPages = ["login", "bind-weixin", "auth-callback"];

// Response interceptors
service.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    NProgress.done();
    return response.data ?? null;
  },
  (error: AxiosError) => {
    NProgress.done();
    let pending: Promise<any> = Promise.resolve();
    const response = error.response as AxiosResponse<any, any>;
    if (error.isAxiosError && response) {
      // error.config.url !== "/user/me"
      if (response.status === 401) {
        const route = router.currentRoute.value;
        const query: any = {};
        if (!isEmpty(store.getters["user/userInfo"])) {
          Notify({ type: "danger", message: SESSION_EXPIRED });
          if (route.fullPath && route.fullPath.toLowerCase().indexOf("login") === -1) {
            query.redirect = route.fullPath;
          }
          pending = pending.then(() => router.push({ name: "login", query }));
          pending = pending.then(() => Promise.reject(new Error(SESSION_EXPIRED)));
        } else {
          if (route.path && !skipPages.some(page => route.path.toLowerCase().indexOf(page) > -1)) {
            pending = pending.then(() => router.push({ name: "login", query }));
          }
          pending = pending.then(() => Promise.reject(new Error(response.data?.message ?? SERVER_NO_ERROR)));
        }
      } else {
        const errorMessage = response.data?.message ?? SERVER_NO_ERROR;
        const serverError = new Error(errorMessage);
        Notify({ type: "danger", message: errorMessage });
        pending = pending.then(() => Promise.reject(serverError));
      }
    } else {
      Notify({ type: "danger", message: error.message });
      pending = pending.then(() => Promise.reject(error));
    }
    return pending;
  }
);

export default service;
