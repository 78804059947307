import lodashIsEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import startsWith from "lodash/startsWith";
import appStore from "@/store";
import debounce from "lodash/debounce";
import { IEnumValue, Nullable } from "@/types";
import { Ref } from "vue";

export function isEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (Object.prototype.toString.call(value) === "[object Number]") {
    return false;
  }

  return lodashIsEmpty(value);
}

export function isEmptyOrWhiteSpace(value: any): boolean {
  return isEmpty(trim(value));
}

export function isEmptyObject(value: any) {
  return lodashIsEmpty(value);
}

export function parseQueryString(str: string) {
  if (isEmpty(str)) {
    return {};
  }
  const token = str;
  const start = token.indexOf("?");
  const queryString: { [key: string]: string } = {};
  let i;
  if (!token || start === -1) {
    return queryString;
  }
  const search = token.substring(start + 1);
  const parts = search.split("&");
  for (i = 0; i < parts.length; i++) {
    const tmp = parts[i].split("=");
    queryString[tmp[0]] = tmp[1];
  }
  return queryString;
}

export function escapeHTMLString(str: string) {
  if (isEmpty(str)) {
    return "";
  }
  str = str + "";
  if (!/[&<>" ]/.test(str)) {
    return str;
  }
  str = str.replace(/&/g, "&amp;");
  str = str.replace(/</g, "&lt;");
  str = str.replace(/>/g, "&gt;");
  str = str.replace(/["]/g, "&quot;");
  str = str.replace(/ /g, "&nbsp;");
  // change only first &nbsp; to blank in order to wrap when long text long
  str = str.replace(/(&nbsp;)+/ig,
    function (findStr, first) {
      return " " + findStr.substr(first.length);
    }
  );
  return str;
}

export function escapeXMLString(str: any) {
  if (isEmpty(str)) {
    return "";
  }
  str = str + "";
  if (!/[&<>"']/.test(str)) return str;

  str = str.replace(/&/g, "&amp;");
  str = str.replace(/</g, "&lt;");
  str = str.replace(/>/g, "&gt;");
  str = str.replace(/["]/g, "&quot;");
  str = str.replace(/[']/g, "&apos;");
  return str;
}

export function escapeJSString(str: any) {
  if (isEmpty(str)) {
    return "";
  }
  str = str.replace(/\\/g, "\\x5c");
  str = str.replace(/["]/g, "\\x22");
  str = str.replace(/[']/g, "\\x27");
  str = str.replace(/</g, "\\x3c");
  str = str.replace(/\r\n/g, "\\x0d\\x0a");
  str = str.replace(/\r/g, "\\x0d");
  str = str.replace(/\n/g, "\\x0a");
  return str;
}

export function escapeURLString(str: string) {
  if (isEmpty(str)) {
    return "";
  }
  return encodeURIComponent(str);
}

export function unescapeHTMLString(str: any) {
  if (isEmpty(str)) {
    return "";
  }
  str = str.replace(/&nbsp;/g, " ");
  str = str.replace(/&amp;/g, "&");
  str = str.replace(/&lt;/g, "<");
  str = str.replace(/&gt;/g, ">");
  str = str.replace(/&quot;/g, "\"");
  str = str.replace(/&nbsp;/g, " ");
  return str;
}

export function unescapeJSString(str: string) {
  if (isEmpty(str)) {
    return "";
  }
  str = str.replace(/\\\\x22/g, "\"");
  str = str.replace(/\\\\x27/g, "'");
  str = str.replace(/\\\\x3c/g, "<");
  str = str.replace(/\\\\x0d\\\\x0a/g, "\\r\\n");
  str = str.replace(/\\\\x0d/g, "\\r");
  str = str.replace(/\\\\x0a/g, "\\n");
  str = str.replace(/\\\\x5c/g, "\\");
  return str;
}

export function unescapeURLString(str: string) {
  if (isEmpty(str)) {
    return "";
  }
  return decodeURIComponent(str);
}

export function nl2br(str: string) {
  str = str + "";
  str = str.replace(/\r\n/g, "<br/>");
  str = str.replace(/\r/g, "<br/>");
  str = str.replace(/\n/g, "<br/>");
  return str;
}

export function isValidEmail(email: string) {
  if (isEmpty(email)) {
    return false;
  }
  // var reg =/^([\w]+)(.[\w]+)*@([\w-]+\.){1,5}([A-Za-z]){2,4}$/;
  // return reg.test(email);
  const tmp = email.split("@");
  if (tmp.length !== 2) {
    return false;
  }

  let pos = email.indexOf(",");
  if (pos >= 0) {
    return false;
  }

  pos = email.indexOf(";");
  return pos < 0;
}

export function isValidMobile(mobile: string) {
  if (isEmpty(mobile)) {
    return false;
  }
  const reg = /^[+]?[0-9]+$/;
  return reg.test(mobile);
}

export function isValidId(val: any) {
  val = trim(val);
  if (!isInteger(val)) return false;
  return parseInt(val) > 0;
}

export { trim as trimString };

export function toNumber(value: any) {
  if (typeof value === "number") {
    return value;
  }
  const num = Number(value);
  return isNaN(num) ? 0 : num;
}

export function isInteger(val: string) {
  if (isEmpty(val)) {
    return false;
  }
  val += "";
  if (val.length === 0) return false;
  const string = "1234567890";
  for (let i = 0; i < val.length; i++) {
    if (string.indexOf(val.charAt(i)) === -1) {
      return false;
    }
  }
  return true;
}

export function isNumeric(val: string) {
  if (val === "") {
    return true;
  }
  return (parseFloat(val) === parseInt(val));
}

export function numberFormat(number: number, decimals?: number, decPoint?: string, thousandsSep?: string) {
  let n = number;
  let prec = decimals;
  const toFixedFix = function (n: number, prec: number) {
    const k = Math.pow(10, prec);
    return (Math.round(n * k) / k).toString();
  };
  n = !isFinite(+n) ? 0 : +n;
  // @ts-ignore
  prec = !isFinite(+prec) ? 0 : Math.abs(prec);
  const sep = (typeof thousandsSep === "undefined") ? "," : thousandsSep;
  const dec = (typeof decPoint === "undefined") ? "." : decPoint;
  let s = (prec > 0) ? toFixedFix(n, prec) : toFixedFix(Math.round(n), prec); // fix for IE parseFloat(0.55).toFixed(0) = 0;
  const abs = toFixedFix(Math.abs(n), prec);
  let _, i;
  if (parseInt(abs) >= 1000) {
    _ = abs.split(/\D/);
    i = _[0].length % 3 || 3;
    _[0] = s.slice(0, i + Number(n < 0)) + _[0].slice(i).replace(/(\d{3})/g, sep + "$1");
    s = _.join(dec);
  } else {
    s = s.replace(".", dec);
  }
  const decPos = s.indexOf(dec);
  if (prec >= 1 && decPos !== -1 && (s.length - decPos - 1) < prec) {
    s += new Array(prec - (s.length - decPos - 1)).join("0") + "0";
  } else if (prec >= 1 && decPos === -1) {
    s += dec + new Array(prec).join("0") + "0";
  }
  return s;
}

export function formatSysNumeric(value: any, precise: number) {
  if (!isNumeric(value + "")) {
    return "";
  }
  if (value + "" === "") {
    value = 0;
  }
  return numberFormat(value, precise, ".", "");
}

/**
 * value: numeric value
 * precise: decimal number
 * isCurrency: default false;
 */
export function formatNumeric(value: number, precise: number, isCurrency?: boolean) {
  let str = formatSysNumeric(value, precise);
  if (str === "") return "";

  let cur = "";
  if (isCurrency) {
    cur = (window as any).USER_LOCALE.CurrencySymbol;
  }

  // remove Negative
  let sign = "";
  if (str.substr(0, 1) === "-") {
    sign = "-";
    str = str.substr(1);
  }
  // thousands Separator
  let pos = str.length;
  let dec = "";
  if (precise > 0) {
    pos = str.indexOf(".", 0);
    if (pos < 0) {
      pos = str.length;
      dec = "";
      for (let i = 0; i < precise; i++) {
        dec += "0";
      }
    } else {
      dec = str.substr(pos + 1);
    }
  }
  if (pos > 3) {
    const arr = [];
    let pos1 = pos % 3;
    if (pos1 > 0) {
      arr[arr.length] = str.substr(0, pos1);
    }
    while (pos1 < pos) {
      arr[arr.length] = str.substr(pos1, 3);
      pos1 += 3;
    }
    str = arr.join((window as any).USER_LOCALE.Separator);
  } else {
    str = str.substr(0, pos);
  }
  str = cur + str;
  return str;
}

export function getEnumMap(data: Array<any>) {
  const map = new Map();
  const items = data;
  if (items && items.length) {
    items.forEach((data: any) => map.set(data.enumValue, data.label));
  }
  return map;
}

export function isAnnualFee(data: string) {
  if (isEmpty(data)) {
    return false;
  }
  return /第\d+年度?年费$/g.test(data);
}

export function getFeeOrderDetailList(items: Array<any>) {
  const newItems = new Array<any>();
  items.forEach(item => {
    if (item.annualFees) {
      const annualFees: Array<any> = item.annualFees;
      delete item.annualFees;
      annualFees.forEach(fee => {
        newItems.push(Object.assign({}, fee, item));
      });
    } else {
      newItems.push(item);
    }
  });
  return newItems;
}

export function formatApiUrl(api: string, params: Nullable<{ [key: string]: any }>) {
  if (startsWith(api, "/")) {
    api = api.substring(1);
  }
  const map = new Map<string, string>();
  let pos;
  if ((pos = api.indexOf("?")) > -1) {
    const apiQueryStr = api.substring(pos + 1);
    let temp: Array<string>;
    apiQueryStr.split("&").forEach(item => {
      temp = item.split("=");
      map.set(temp[0], temp[1]);
    });
  }

  if (params != null) {
    Object.keys(params).forEach(item => map.set(item, params[item]));
  }
  const parts: Array<string> = [];
  map.forEach((value, key) => parts.push(`${key}=${value}`));
  if (pos > -1) {
    api = api.substring(0, pos);
  }
  return `/${api}?${parts.join("&")}`;
}

export function getEnumLabel(enums: Map<string, IEnumValue> | string, cellValue: any) {
  if (typeof enums === "string") {
    enums = getAppEnum(enums as string) ?? new Map<string, IEnumValue>();
  }
  return (enums.get(cellValue)?.name) ?? "";
}

export function getAppEnum(enumKey: string): Map<string, IEnumValue> {
  if (!appStore || !enumKey) {
    return new Map<string, IEnumValue>();
  }
  const appEnums = appStore.getters["app/enums"] as Map<string, Map<string, IEnumValue>>;
  if (!appEnums) {
    return new Map<string, IEnumValue>();
  }
  return appEnums.get(enumKey) ?? new Map<string, IEnumValue>();
}

/**
 * 保留两位小数并且整数部分三位一个逗号分隔符的数字金钱标准表示法：
 * 这里假设我们即不知道输入数字的整数位数，也不知道小数位数
 * 将100000转为100,000.00形式
 */
export function dealNumber(money: any) {
  if (money !== null && money !== undefined) {
    if (money === Number.POSITIVE_INFINITY || money === Number.NEGATIVE_INFINITY) {
      return money;
    }
    money = String(money);
    const left = money.split(".")[0];
    const right = money.split(".")[1];
    const temp = left.split("").reverse().join("").match(/(\d{1,3})/g);
    let res = (Number(money) < 0 ? "-" : "") + temp.join(",").split("").reverse().join("");
    if (right !== null && right !== undefined) {
      res = res + "." + right;
    }
    return res;
  } else if (money === 0) { // 注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
    return "0";
  } else {
    return "";
  }
}

/**
 * 将100,000.00转为100000形式
 * @param money
 */
export function undoNumber(money: string) {
  if (money && money !== null) {
    money = String(money);
    const group = money.split(".");
    const left = group[0].split(",").join("");
    return Number(left + "." + group[1]);
  } else {
    return "";
  }
}

export function emptyFn() {
  //empty function
}

export const emptyObject = Object.freeze({});

// eslint-disable-next-line space-before-function-paren
export function DelayInvoke<T extends (...args: any) => any>(time?: number): MethodDecorator {
  if (time === null || time === undefined) {
    time = 500;
  }
  if (time < 0) {
    time = 0;
  }
  return function fn(target, propertyKey, descriptor) {
    const handler = descriptor.value;
    if (handler && typeof handler === "function") {
      descriptor.value = debounce(descriptor.value as any, time) as any;
    }
  };
}

export function getFileExt(file: string) {
  if (isEmptyOrWhiteSpace(file)) {
    return file;
  }
  const match = file.match(/\.[^.]+$/g);
  return match?.[0] ?? "";
}

export function getIntValue(value: number) {
  return Math.floor(value);
}

export function wrapSearchFunction(loading: Ref<boolean>, handler: (...args: any[]) => Promise<unknown>) {
  return (...args: any) => {
    try {
      loading.value = true;
      return handler(...args);
    } finally {
      loading.value = false;
    }
  };
}

export function validListJsonb(items: Nullable<Array<any>>, prop: string) {
  if (!items) {
    return;
  }
  items.forEach(item => validJsonb(item, prop));
}

export function validJsonb(dataItem: any, prop: string) {
  if (dataItem && isEmptyObject(dataItem[prop])) {
    Reflect.set(dataItem, prop, {});
  }
}

export function formatFileSize(limit: number) {
  let size = "";
  if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
    size = limit.toFixed(2) + "B";
  } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB";
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB";
  } else {                                            //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  const sizeStr = size + "";                        //转成字符串
  const index = sizeStr.indexOf(".");                    //获取小数点处的索引
  const dou = sizeStr.substr(index + 1, 2);            //获取小数点后两位的值
  if (dou == "00") {                                //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }
  return size;
}
