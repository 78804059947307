import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import ActionBarLayout from "@/layouts/ActionBarLayout.vue";
import DetailLayout from "@/layouts/DetailLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: ActionBarLayout,
    redirect: { name: "patent" },
    meta: { auth: true },
    children: [
      {
        path: "patents/:id",
        name: "patent-detail",
        component: () => import("@/views/patent/PatentDetail.vue"),
        meta: {
          title: "专利详情",
          nav: false,
          inPage: true
        }
      },
      {
        path: "patents",
        name: "patent",
        component: () => import("@/views/patent/PatentList.vue"),
        meta: {
          title: "专利中心"
        }
      },
      {
        path: "add-patent",
        name: "add-patent",
        component: () => import("@/views/patent/AddPatent.vue"),
        meta: {
          title: "添加专利"
        }
      },
      {
        path: "message",
        name: "message",
        component: () => import("@/views/message/MessageList.vue"),
        meta: {
          title: "消息中心"
        }
      },
      {
        path: "user-manager",
        name: "user-manager",
        component: () => import("@/views/user/UserInfo.vue"),
        meta: {
          title: "用户中心"
        }
      }
    ]
  },
  {
    path: "/",
    name: "detail-layout",
    component: DetailLayout,
    meta: { auth: true },
    children: [
      {
        path: "message-detail",
        name: "message-detail",
        component: () => import("@/views/message/MessageDetail.vue"),
        meta: {
          title: "消息详情"
        }
      },
      {
        path: "message-setting",
        name: "message-setting",
        component: () => import("@/views/user/MessageSetting.vue"),
        meta: {
          title: "消息设置"
        }
      },
      {
        path: "user-detail",
        name: "user-detail",
        component: () => import("@/views/user/UserDetail.vue"),
        meta: {
          title: "用户信息"
        }
      },
      {
        path: "contract-us",
        name: "contract-us",
        component: () => import("@/views/common/ContractUs.vue"),
        meta: {
          title: "联系我们"
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/WeiXinAuth.vue")
  },
  {
    path: "/login-manual",
    name: "login-manual",
    component: () => import("@/views/auth/LoginManual.vue")
  },
  {
    path: "/bind-weixin",
    name: "bind-weixin",
    component: () => import("@/views/auth/BindWeiXin.vue")
  },
  {
    path: "/auth-callback",
    name: "wx-auth-callback",
    component: () => import("@/views/auth/WeiXinAuthCallBack.vue")
  },
  {
    path: "/no-perm",
    name: "no-perm",
    component: () => import("@/views/common/NoPermission.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
