import { IOrgInfo } from "@/types/model";
import service from "@/utils/request";

const api = {
  orgInfo: "/opened/org-info",
  weixinApi: "/opened/weixin-api"
};

export function getOrgInfo(): Promise<IOrgInfo> {
  return service.get(api.orgInfo);
}

export function getWxConfig() {
  return service.get(api.weixinApi);
}
