import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { storeKey } from "./store";

import { install as installUI } from "@/config/installComponent";

import "@/styles/app.scss";

import "./permission";


const app = createApp(App).use(store, storeKey).use(router);

installUI(app);

app.mount("#app");
