export const Not_Found_Token = "";
export const INVALID_USER_INFO = "验证失败，请重新登录";

export const LOGIN_SUCCESS = "登录成功";

export const SESSION_EXPIRED = "您的会话已经过期或没有权限访问该数据，请重新登录";
export const SERVER_NO_ERROR = "服务器操作错误，请稍后重试";

export const ERROR_TITLE = "错误";

export const MSG_DELETE_CONFIRM = "是否删除当前的数据";
export const MSG_DELETE_SUCCESS = "删除成功";

export const MSG_SAVE_SUCCESS = "保存成功";
export const MSG_INVALID_DATA = "页面验证失败，请检查必填项";
export const MSG_SELECT_SUB_ITEM = "请选择一个子节点";

export const MSG_DATA_FORMAT_ERROR = "数据格式不正确，请稍后重试";

export const MSG_DISABLE_DEVICE = "是否禁用这些设备，禁用后这些设备将被删除";
