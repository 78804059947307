import service from "@/utils/request";

const api = {
  login: "/auth/login",
  logout: "/users/logout",
  weixinBinding: "/auth/weixin-binding",
  weixinBindingCheck: "/auth/weixin-binding-check",
  sendAuthCode: "/auth/send-auth-code"
};

export declare interface LoginEntity {
  loginName?: string;
  phone?: string;
  email?: string;
  password?: string;
  authCode?: string;
  weixinUid?: string;
}

export declare interface WeiXinBindingCheck {
  name: string;
  phone: string;
}

export declare interface WeiXinBinding {
  name: string;
  phone: string;
  weixinUid: string;
  authCode: string;
}

export async function login(info: LoginEntity) {
  return await service.post(api.login, info);
}

export async function logout() {
  return await service.delete(api.logout);
}

export function bindWeiXin(config: WeiXinBinding) {
  return service.post(api.weixinBinding, config);
}

export function setAuthCode(phone: string) {
  return service.post(api.sendAuthCode, { phone });
}

export function checkWeixinBind(data: WeiXinBindingCheck) {
  return service.post(api.weixinBindingCheck, data);
}
