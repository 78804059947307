import {
  Popover,
  NavBar,
  Tabbar,
  TabbarItem,
  Form,
  Field,
  Button,
  Loading,
  Popup,
  Search,
  Skeleton,
  List,
  Empty,
  Icon,
  Tag,
  Switch,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Badge,
  ActionBar,
  ActionBarButton,
  ActionBarIcon,
  Tab,
  Tabs,
  NoticeBar,
  Radio,
  RadioGroup,
  Step,
  Steps
} from "vant";
import { App } from "vue";

export function install(app: App) {
  app.use(NavBar);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(Form);
  app.use(Field);
  app.use(Button);
  app.use(Loading);
  app.use(Popup);
  app.use(Search);
  app.use(Skeleton);
  app.use(List);
  app.use(Empty);
  app.use(Icon);
  app.use(Tag);
  app.use(Switch);
  app.use(CellGroup);
  app.use(Checkbox);
  app.use(CheckboxGroup);
  app.use(Popover);
  app.use(Badge);
  app.use(ActionBar);
  app.use(ActionBarButton);
  app.use(ActionBarIcon);
  app.use(Tabs);
  app.use(Tab);
  app.use(NoticeBar);
  app.use(Step);
  app.use(Steps);
  app.use(Radio);
  app.use(RadioGroup);
}
