import { Module } from "vuex";
import { login, LoginEntity, logout } from "@/api/auth";
import { getUserInfo, unBindWx } from "@/api/user";
import { isEmptyOrWhiteSpace } from "@/utils/helper";
import { MSG_INVALID_DATA } from "@/constants/message";
import store from "@/store";

export const module: Module<any, any> = {
  namespaced: true,
  state: () => {
    return {
      userInfo: null
    };
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    }
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    }
  },
  actions: {
    async login(context, payload: LoginEntity) {
      if (isEmptyOrWhiteSpace(payload.loginName) && isEmptyOrWhiteSpace(payload.email) && isEmptyOrWhiteSpace(payload.phone) && isEmptyOrWhiteSpace(payload.weixinUid)) {
        throw new Error(MSG_INVALID_DATA);
      }
      return await login(payload);
    },
    async getUserInfo(context) {
      const userInfo = await getUserInfo();
      if (userInfo) {
        context.commit("setUserInfo", userInfo);
      } else {
        context.commit("setUserInfo", null);
      }
    },
    async logout({ commit }) {
      store.commit("app/setWxData", null);
      commit("setUserInfo", null);
      await unBindWx();
      await logout();
    }
  }
};
